<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-calendar-clock
      </v-icon> Banner Creation
    </h2>
    <br>
    <v-stepper
      v-model="createBanner"
      shaped
      class="my-stepper-style"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="createBanner > 1"
          step="1"
          color="#FF3700"
        >
          File Upload
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="createBanner > 2"
          step="2"
          color="#FF3700"
        >
          Review File Content
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="createBanner > 3"
          color="#FF3700"
          step="3"
        >
          Create Banner
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          color="#FF3700"
          step="4"
        >
          Link Device
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <file-upload @second-step="firstStepChange()" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <review-list
            @third-step="secondStepChange()"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card
            shaped
            color="#ECEFF1"
          >
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="bannerName.name"
                        label="Banner Name*"
                        hint="Name for your reference - 'ChristmasEve'.(Only alpha dashes allowed)"
                        :rules="[(v) => !!v || 'This field is required']"
                        type="text"
                        @keydown.space.prevent
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="'required'"
                        name="From datetime"
                      >
                        <v-datetime-picker
                          v-model="start"
                          label="From*"
                          date-format="yyyy-MM-dd"
                          time-format="HH:mm:ss"
                          :text-field-props="{
                            suffix: userTimeZone,
                            errorMessages: errors,
                            class: 'custom-label-color',
                          }"
                          :date-picker-props="{
                            headerColor: '#37474F',
                            min: minStartDate
                          }"
                          :time-picker-props="{
                            headerColor: '#37474F'
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon
                              large
                              color="#37474F"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon
                              color="#37474F"
                              large
                            >
                              mdi-alarm
                            </v-icon>
                          </template>
                        </v-datetime-picker>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <v-datetime-picker
                        v-model="end"
                        label="To"
                        :text-field-props="{
                          hint: 'Leave blank if never ends',
                          persistentHint: true,
                          suffix: userTimeZone
                        }"
                        :date-picker-props="{
                          headerColor: '#37474F',
                          min: minEndDate
                        }"
                        :time-picker-props="{
                          headerColor: '#37474F'
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon
                            color="#37474F"
                            large
                          >
                            mdi-alarm
                          </v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    class="text-left"
                  >
                    <v-btn
                      v-if="showScheduleBtn"
                      small
                      dark
                      class="mt-2"
                      color="#FF3700"
                      depressed
                      default
                      rounded
                      @click="showAdvancedSchedule = true"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Advanced Schedule
                    </v-btn>
                    <advanced-scheduling v-model="showAdvancedSchedule" />
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <advanced-scheduling-list @show-content-form="showContentForm" />
                    <advanced-schedule-edit-before-creation
                      v-if="showScheduleContent"
                      :editvalues="editScheduleContent"
                      :scheduleindex="scheduleIndex"
                      @closed="showScheduleContent = false"
                    />
                  </v-col>
                </v-container>
                <br>
                <p class="text-sm-left">
                  *indicates required field
                </p>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="#FF3700"
                @click="next()"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <select-digi-boards
            :banner-form-details="bannerFormDetails"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <br>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import FileUpload from './FileUpload.vue';
  import ReviewList from './ReviewList.vue';
  import moment from 'moment-timezone';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';
  import AdvancedScheduling from '../component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from '../component/schedules/AdvancedSchedulingList.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SelectDigiBoards from './SelectDigiBoards.vue';
  import AdvancedScheduleEditBeforeCreation from '../component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'CreateNewBanner',
    components: {
      'centre-spinner': spinner,
      AdvancedScheduling,
      AdvancedSchedulingList,
      FileUpload,
      ReviewList,
      SelectDigiBoards,
      AdvancedScheduleEditBeforeCreation,
    },
    mixins: [global],
    data () {
      return {
        createBanner: 1,
        showAdvancedSchedule: false,
        start: '',
        end: '',
        minStartDate: moment().format('YYYY-MM-DD'),
        helper: new ModuleHelper(),
        loading: false,
        showScheduleContent: false,
        scheduleIndex: 0,
        editScheduleContent: {},
         bannerFormDetails: {
          bannerName: '',
          start: '',
          end: '',
        },
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('create-banner') === false || vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_BANNER) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExist(Constants.MODULE_BANNER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
      bannerName () {
        return this.$store.getters['banners/getBannerName'];
      },
       userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    methods: {
      firstStepChange () {
        this.createBanner = 2;
      },
      secondStepChange () {
        this.createBanner = 3;
      },
      async next () {
        if (this.start === null || this.start === '' || this.bannerName.name === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.bannerFormDetails.bannerName = this.bannerName.name;
        this.bannerFormDetails.start = this.start;
        this.bannerFormDetails.end = this.end;
        setTimeout(() => {
          this.loading = false;
          this.createBanner = 4;
        }, 2000);
      },
       showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
    },
  };
</script>
<style scoped>
.my-stepper-style {
  background-color: #CFD8DC !important;
  margin-right: 30px;
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
